import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '@/components/Layout';
import ServiceHero from '@/components/ServiceHero';
import ServiceLinkItem from '@/components/ServiceLinkItem';

import { SERVICE_GROWTH } from '@/referentials/routes';

export default function DataSciencePage({ data }) {
  const { t } = useTranslation();

  const contentDataScience = {
    name: t('dataTitle'),
    bg: 'bg-green',
    blobFirstColor: '#CEE4D6',
    blobSecondColor: '#D5ECDD',
    content: t('dataContent'),
    tags: [t('tagMachineLearning'), t('tagBigData'), t('tagAnalytics')],
  };

  return (
    <Layout
      title="Services - Data Science"
      description="Le charrette.club design, code et fait grandir des produits digitaux innovants."
      className="mt-8"
    >
      <ServiceHero tags={contentDataScience} img={data.science} />
      <ServiceLinkItem
        title={`${t('next')} ${t('growthTitle')}`}
        to={SERVICE_GROWTH}
      />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["services", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    science: file(relativePath: { eq: "data-science.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
